.ceo-ar {
  direction: rtl;
  font-family: "cairo";
}
.ceo-ar .newstyle {
  font-size: 16px;
}
.ceo-ar .new {
  display: block;
  margin-right: -600px;
  text-align: right;
  font-family: "cairo" !important;
}

.ceo-ar #special #exchange h3 {
  font-family: "cairo" !important;
  font-size: 26px;
}
.ceo-ar #special .newFont {
  font-family: "cairo" !important;
}
.ceo-ar #special #exchange .special_1r h3 {
  font-family: "cairo" !important;
}
.ceo-ar #exchange .special_1r p {
  font-family: "cairo" !important;
  text-align: right;
  line-height: 1.9;
}

@media (max-width: 768px) {
  .ceo-ar #special #exchange h3 {
    margin-left: 20px;
    font-size: 24px;
    margin-bottom: -30px;
    margin-top: 25px;
  }
}
@media (max-width: 1385px) {
  .ceo-ar .new {
    margin-right: -90%;
  }
}
@media (max-width: 768px) {
  .ceo-ar .new {
    margin-right: 0;
  }
}
