#serv {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #f5f0e9;
  margin-top: 40px;
}
.serv_2i {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
.serv_1 h2 {
  font-family: "Goblin One", cursive;
  font-weight: bold;
  line-height: 1.2;
  font-size: 36px;
}
.serv_2i1 {
  background: #fff;
  padding: 15px 20px 25px 20px;
}

@media (max-width: 767px) {
  .serv_2i1 {
    width: 270px;
    margin: 0 auto;
  }
}
.serv_2i2i1 span a:hover {
  background: #404a3d;
  color: #fff;
}
.serv_2i2i1 {
  left: 20px;
  top: -25px;
}
.serv_2i1 h4 {
  font-size: 22px;
}

#serv span .col_4 {
  color: #8e1e22 !important;
}

.grid .effect-jazz.new {
  position: relative;
  float: left;
  overflow: hidden;
  width: 100%;
  height: 320px;
  text-align: center;
  cursor: pointer;
}
figure.effect-jazz figcaption::after,
figure.effect-jazz img {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  height: 100%;
}

figure.effect-jazz:hover img {
  -webkit-transform: scale3d(1.05, 1.05, 1);
  transform: scale3d(1.05, 1.05, 1);
}

@media (max-width: 991px) {
  .grid .effect-jazz.new {
    background-image: none;
  }
}
@media (max-width: 767px) {
  #serv {
    margin-top: 0;
  }
  .serv_2i {
    margin-top: 15px;
  }
  .serv_2i1 h4 {
    font-size: 20px;
  }
}
/*********************serv_end****************/
[data-quantity] {
  position: relative;
  width: 100%;
  max-width: 11rem;
  padding: 0;
  margin: 0;
  border: 0;
}

[data-quantity] legend {
  display: none;
}

[data-quantity] input {
  font-size: 18px;
  height: 4rem;
  padding: 0 4rem;
  border-radius: 2rem;
  border: 0;
  background: #fff;
  color: #222;
  box-shadow: 0 10px 65px -10px rgba(0, 0, 0, 0.25);
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  font-weight: lighter;
}

[data-quantity] input:focus {
  outline: none;
  box-shadow: 0 5px 55px -10px rgba(0, 0, 0, 0.2), 0 0 4px #3fb0ff; /* Allows border radius on focus */
}

[data-quantity] input[type="number"]::-webkit-inner-spin-button,
[data-quantity] input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[data-quantity] input[type="number"] {
  -moz-appearance: textfield;
}

[data-quantity] button {
  position: absolute;
  width: 2.8rem;
  height: 2.8rem;
  top: 0.6rem;
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="50"><path d="M17 25h16M67 25h16M75 17v16" fill="none" stroke="rgb(59,69,66)" stroke-width="1.5px" stroke-linecap="round" stroke-linejoin="round" /></svg>')
    no-repeat 0 0;
  background-size: 5.6rem 2.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
  border-radius: 1.4rem;
  cursor: pointer;
  transition: opacity 0.15s;
  opacity: 0.5;
}

[data-quantity] button:active {
  background-position-y: 1px;
  box-shadow: inset 0 2px 12px -4px #c5d1d9;
}

[data-quantity] button:focus {
  outline: none;
}

[data-quantity] button:hover {
  opacity: 1;
}

[data-quantity] button.sub {
  left: 0.6rem;
}

[data-quantity] button.add {
  right: 0.6rem;
  background-position-x: -2.8rem;
}

.serv_2i .certiimage {
  width: 400px;
  height: 500px;
}
.serv_2i .certiimage img {
  max-width: 100%;
  height: 100%;
}
