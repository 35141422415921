.home-slider-ar .carousel-inner .item .hero .headSlider {
  font-size: 3.6rem;
  font-family: "cairo" !important;
}
.home-slider-ar .carousel-inner .item .hero .descSlider {
  font-size: 1.8rem;
  font-weight: 600;
  font-family: "cairo" !important;
}
.home-slider-ar .carousel-inner .item .hero a {
  font-size: 1.7rem;
  font-weight: 500;
  font-family: "cairo" !important;
}

@media (min-width: 768px) {
  .home-slider-ar .carousel-inner .item .hero {
    left: 65%;
  }
}
