.team-members .team-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 330px);
  justify-content: center;
  gap: 30px 40px;
  padding: 0 40px 50px;
}
.team-members .member {
  /* width: 330px; */
  height: 400px;
  border-radius: 10px;
  box-shadow: 0 22px 40px -10px;
  position: relative;
  overflow: hidden;
}
.team-members .member::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255, 0.1);
}

.team-members .member .image {
  width: 100%;
  height: 100%;
}
.team-members .member .image img {
  width: 100%;
  height: 100%;
}

.team-members .member .member-info {
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  transition: 0.5s ease-in-out;
  text-align: center;
  padding: 15px 0;
}
.team-members .member .member-info span {
  display: block;
  color: white;
  letter-spacing: 1px;
  line-height: 1.6;
  font-weight: 500;
  /* font-family: "poppins"; */
}
.team-members .member .member-info .name {
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.team-members .member .member-info .title {
  font-size: 1.3rem;
}

@media (max-width: 420px) {
  .team-members .team-container {
    grid-template-columns: repeat(auto-fill, 280px);
  }

  .team-members .member {
    height: 370px;
  }
}
