.main-cat {
  width: 285px;
  height: 298px;
  overflow: hidden;
  margin-bottom: 20px;
}
.main-cat .serv_2i2 {
  width: 285px;
}
.main-cat .cat-image {
  width: 285px;
  height: 220px;
  min-width: 285px;
  min-height: 220px;
  max-width: 285px;
  max-height: 220px;
}
.main-cat .serv_2i1 {
  width: 285px;
}
.main-cat figure {
  background-color: #ddd;
}

@media (min-width: 690px) and (max-width: 880px) {
  .cat-container {
    margin-left: 50px;
  }
}
@media (max-width: 666px) {
  .main-cat {
    margin-left: 90px;
  }
}
@media (max-width: 450px) {
  .main-cat {
    margin-left: 25px;
  }
}
