.logo-container {
  display: inline-block;
  width: 280px;
}
.logo-container img {
  max-width: 100%;
  max-height: 100%;
}

#header .navbar-brand {
  padding: 0 0 0 40px;
  font-size: 28px;
  font-weight: bold;
  margin-left: 250px;
  color: #333;
  display: none;
}
#header .navbar-brand img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -280px;
  margin-top: -10px;
}

.cd-secondary-nav.is-fixed .navbar-brand {
  display: block !important;
  height: 65px;
}
.cd-secondary-nav.is-fixed .navbar-brand img {
  max-width: 110% !important;
  max-height: 120% !important;
  padding-top: 7px;
}
.cd-secondary-nav.is-fixed .m_tag {
  padding: 15px 9px !important;
  font-size: 13px;
}

/* Start Media Query */
@media (min-width: 768px) and (max-width: 1135px) {
  .header-en .navbar-header {
    width: 22% !important;
  }
  .header-en .navbar-header .navbar-brand {
    width: 100% !important;
    padding: 0 0 0 10px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about-menu {
    width: 250px !important;
  }
  .about-menu li a {
    font-size: 1.3rem !important;
  }
}
@media (max-width: 767px) {
  .logo-container img {
    max-width: 150%;
    max-height: 135%;
  }
  .header-en .navbar-header .navbar-brand {
    display: block !important;
    width: 240px !important;
    margin: 0 !important;
    padding: 0px !important;
    padding-left: 3px !important;
  }
  .header-en .navbar-header .navbar-brand img {
    margin: 0 !important;
    transform: translateY(-10px);
  }
}
