/* 
Template Name: Dairy Farm
File: Layout CSS
Author: TemplatesOnWeb
Author URI: https://www.templateonweb.com/
Licence: <a href="https://www.templateonweb.com/license">Website Template Licence</a>
*/
#contact {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #eee;
  font-family: "EXO 2";
}
.checkout_1l1 .form-control {
  border-color: #eee;
  box-shadow: none;
  height: 50px;
  margin-top: 15px;
  border-radius: 0;
  font-size: 16px;
  font-family: "EXO 2";
}
.checkout_1l1 .form_1 {
  height: 180px;
}
.contact_1lm {
  box-shadow: 0 0 10px #ddd;
  padding: 40px 20px;
}
.contact_1r1 span {
  display: inline-block;
  background: #629bd0;
  color: #fff;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 3px;
  text-align: center;
  font-size: 18px;
}
.contact_1rm {
  box-shadow: 0 0 10px #ddd;
  padding: 40px 20px;
}
.contact_1r1 {
  margin-top: 30px;
}
.contact_2 {
  margin-top: 30px;
}
.checkout_1l1 label {
  margin: 0;
  padding: 0;
}
.checkout_1l1 .required_star {
  font-size: 14px;
  margin-left: 4px;
}
.sending-message,
.success-message,
.failed-message {
  display: block;
  padding-top: 10px;
  padding-left: 1px;
}
.error-message,
.failed-message {
  color: red;
}
.error-message {
  display: inline-block;
  margin-top: 5px;
  font-size: 1.3rem;
  letter-spacing: 0.5px;
  padding-left: 5px;
}
.sending-message {
  font-size: 14px;
  color: #333;
}
.success-message {
  color: #26c70a;
}
.success-message i,
.failed-message i {
  margin-left: 6px;
  font-size: 19px;
  position: relative;
  top: 1px;
}
.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #8e1e22;
  border-radius: 50%;
  margin-left: 15px;
  position: relative;
  top: 3px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Start Media Query */
@media screen and (max-width: 767px) {
  #contact {
    text-align: center;
  }
  .contact_1rm {
    margin-top: 15px;
  }
}

@media (max-width:991px) {
  iframe{
    width: 360px !important;
    margin-bottom: 10px;
  }
}
#contact .contact_1r1.call p{
  text-align: left;
}
@media (max-width:768px) {
  #contact .contact_1r1 p{
      text-align: center !important;
  }
}