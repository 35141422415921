.category-items {
  background-color: #515151;
  padding: 20px 0;
  color: white;
}
.category-items .filter-icon {
  display: none;
}
.category-items .container {
  display: flex;
  position: relative;
}

.category-items .container .filter {
  position: relative;
  width: 260px;
  margin-right: 30px;
  border: 1px solid #777;
  border-radius: 5px;
  max-height: 1166px;
  overflow-y: auto;
}
.category-items .container .filter .box {
  border-bottom: 1px solid #777;
  padding: 25px 20px;
}
.category-items .container .filter .box span {
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
  text-overflow: ellipsis;
}
.category-items .container .filter .box .choice:not(:last-of-type) {
  margin-bottom: 15px;
}
.category-items .container .filter .box .choices .choice {
  display: flex;
  flex-wrap: nowrap;
  gap: 15px;
}
.category-items .container .filter .box .choices .choice input {
  display: inline-block;
  margin: 0;
  /* margin-right: 14px; */
  width: 22px;
  height: 22px;
  cursor: pointer;
  outline: 1.7px solid #777;
}
.category-items .container .filter .box .choices .choice label {
  flex: 1;
  color: #aaa;
  transform: translateY(-6px);
  cursor: pointer;
  font-size: 1.3rem;
  letter-spacing: 0.5px;
  line-height: 1.7;
}
.category-items .container .filter .box .choices .choice label:hover {
  color: #999;
}

.category-items .container .products {
  flex: 1;
}
.category-items .container .products .item {
  display: grid;
  grid-template-columns: 26% 54% 18%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid white;
  padding: 25px 0;
}
.category-items .container .products .item .image {
  max-width: 240px;
  height: 65px;
}
.category-items .container .products .item .image img {
  width: 100%;
  height: 100%;
}
.category-items .container .products .item span {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 1px;
  padding-left: 5px;
  transform: translateY(-5px);
}
.category-items .container .products .item a {
  border: 1px solid white;
  color: white;
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px 5px;
  transition: all 0.3s;
}
.category-items .container .products .item a .icon {
  margin-right: 10px;
  display: inline;
}
.category-items .container .products .item a:hover {
  background-color: #8e1e22;
}

.item-loading,
.item-alert,
.item-alert .cart-btn {
  width: 70%;
  margin: 30px auto;
}
.item-alert .cart-btn a {
  display: block;
  width: fit-content;
  margin: auto;
  padding: 15px 50px !important;
  text-align: center;
}

/* Start Media Query */
@media (max-width: 992px) {
  .category-items .filter-icon {
    display: block;
    width: fit-content;
    padding: 0 10px;
    margin-bottom: 15px;
    margin-left: 1%;
    color: #ee1e24;
    letter-spacing: 0.6px;
    font-size: 1.4rem;
    font-weight: 500;
    cursor: pointer;
  }
  .category-items .filter-icon .icon {
    margin-left: 6px;
    transform: translateY(3.5px);
  }
  .category-items .container .filter {
    position: absolute;
    left: 0.5%;
    top: 0;
    width: 99%;
    background-color: white;
    z-index: 100;
    transition: 0.3s;
    overflow: hidden;
    height: 0;
  }
  .category-items .container .filter.show {
    height: 1166px;
    overflow-y: auto;
  }
  .category-items .container .filter .box span {
    color: #818080;
    font-weight: 600;
    font-size: 1.9rem;
  }
  .category-items .container .filter .box .choices .choice label {
    color: #777;
  }
}
@media (max-width: 700px) {
  .category-items .container .products .item {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
  }
  .category-items .container .products .item .image {
    width: 30%;
    height: 60px;
  }
  .category-items .container .products .item span {
    width: 66.5%;
    font-size: 1.3rem;
    letter-spacing: 1.3px;
  }
  .category-items .container .products .item a {
    width: 40%;
  }
}
@media (max-width: 575px) {
  .category-items .container .products .item .image {
    width: 60%;
  }
  .category-items .container .products .item span {
    width: 95%;
  }
}

.carousel-inner .news.categoryname {
  display: block;
  padding: 10px;
  width: fit-content;
  font-family: "Exo 2", sans-serif;
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff !important;
  background-image: linear-gradient(
    to right,
    rgba(233, 228, 228, 0),
    rgb(160, 150, 150)
  );
}
