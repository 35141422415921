/* Start Media Query */
@media (min-width: 767px) {
  .special_1 {
    margin-top: 40px;
    display: flex;
    flex-wrap: nowrap;
    column-gap: 60px;
  }
  .ceo-image {
    width: 460px;
    margin-top: 20px;
  }
  .ceo-image h3 {
    width: fit-content;
    margin: auto;
    margin-top: 30px;
    font-size: 3rem;
  }

  .ceo-word {
    flex: 1;
  }
  .ceo-word .ceopa {
    letter-spacing: 0.4px;
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .special_1 {
    gap: 25px;
  }
  .ceo-image {
    width: 350px;
  }
  .ceo-image img {
    height: 300px !important;
  }
  .ceo-image h3 {
    font-size: 2.5rem;
  }
}
