.history-ar p {
  font-family: "cairo" !important;
  text-align: right;
}
.history-ar .newstyle {
  font-size: 16px;
}
.history-ar .hero {
  direction: rtl;
}
.history-ar .new {
  display: block;
  margin-right: -600px;
  text-align: right;
  font-family: "cairo";
}
.history-ar #team_h h2 {
  font-family: "cairo" !important;
}
.history-ar .story-box .title-box h3 {
  text-align: right;
  font-family: "cairo" !important;
  font-weight: 800;
}

.history-ar #team_h p {
  font-family: "cairo";
  font-weight: 300;
  word-spacing: normal;
  letter-spacing: normal;
  line-height: 2;
}

@media (max-width: 1385px) {
  .history-ar .new {
    margin-right: -90%;
  }
}
@media (max-width: 768px) {
  .history-ar #team_h i {
    margin-top: 50px;
  }
  .history-ar #team_h p {
    word-spacing: normal;
    letter-spacing: normal;
  }
  .history-ar .new {
    margin-right: 0;
  }
  .history-ar .story-box .title-box h3 {
    text-align: center;
  }
}
.history-ar #team_h p {
  text-align: center !important;
}
