.certificate-ar {
  direction: rtl;
  font-family: "cairo" !important;
}

.certificate-ar .new {
  display: block;
  margin-right: -600px;
  text-align: right;
  font-family: "cairo" !important;

}
#serv h2 {
  font-family: "cairo" !important;
}

@media (max-width: 1385px) {
  .certificate-ar .new {
    margin-right: -90%;
  }
}
@media (max-width: 768px) {
  .certificate-ar .new {
    margin-right: 0;
  }
}
.serv_2i .certiimage {
  width: 400px;
  height: 500px;
}
.serv_2i .certiimage img {
  max-width: 100%;
  height: 100%;
}
