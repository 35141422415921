.team-ar {
  direction: rtl;
  font-family: "cairo";
}
.team-ar .newstyle {
  font-size: 16px;
}
.team-ar .new {
  display: block;
  margin-right: -600px;
  text-align: right;
  font-family: "cairo" !important;
}

@media (max-width: 1385px) {
  .team-ar .new {
    margin-right: -90%;
  }
}
@media (max-width: 768px) {
  .team-ar .new {
    margin-right: 0;
  }
}

.team-ar .ceo .container .box::before,
.team-ar .team .container .box::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-color: #f3f3f3;
  border-radius: 10px;
}
.team-ar #special .newFont {
  font-family: "cairo" !important;
}

.team-ar .team-members .team-container .member .member-info .name {
  font-size: 1.7rem;
}
.team-ar .team-members .team-container .member .member-info .title {
  font-size: 1.2rem;
}
