.fade-carousel {
  position: relative;
  min-height: 600px;
}
.fade-carousel .carousel-inner .item {
  min-height: 600px;
}
.fade-carousel .carousel-indicators > li {
  margin: 0 4px;
  opacity: 0.8;
}
.fade-carousel .carousel-indicators > li.active {
  opacity: 1;
}
.carousel-inner {
  background-color: #e1d9d9;
}
.carousel-inner h5 {
  font-size: 14px !important;
}

/********************************/
/*          Hero Headers        */
/********************************/
.hero {
  position: absolute;
  width: 50%;
  top: 50%;
  left: 32%;
  z-index: 2;
  text-transform: capitalize;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  -o-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
.fade-carousel .carousel-inner .item .hero {
  opacity: 0;
  -webkit-transition: 2s all ease-in-out 0.1s;
  -moz-transition: 2s all ease-in-out 0.1s;
  -ms-transition: 2s all ease-in-out 0.1s;
  -o-transition: 2s all ease-in-out 0.1s;
  transition: 2s all ease-in-out 0.1s;
}
.fade-carousel .carousel-inner .item.active .hero {
  opacity: 1;
  -webkit-transition: 2s all ease-in-out 0.1s;
  -moz-transition: 2s all ease-in-out 0.1s;
  -ms-transition: 2s all ease-in-out 0.1s;
  -o-transition: 2s all ease-in-out 0.1s;
  transition: 2s all ease-in-out 0.1s;
}

/********************************/
/*            Overlay           */
/********************************/
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 1;
  background: #0000008c;
}

/********************************/
/*          Custom Buttons      */
/********************************/

/********************************/
/*       Slides backgrounds     */
/********************************/
.fade-carousel .slides .slide-1,
.fade-carousel .slides .slide-2,
.fade-carousel .slides .slide-3,
.fade-carousel .slides .slide-4,
.fade-carousel .slides .slide-5,
.fade-carousel .slides .slide-6,
.fade-carousel .slides .slide-7,
.fade-carousel .slides .slide-8,
.fade-carousel .slides .slide-9,
.fade-carousel .slides .slide-10,
.fade-carousel .slides .slide-11,
.fade-carousel .slides .slide-12,
.fade-carousel .slides .slide-13,
.fade-carousel .slides .slide-14,
.fade-carousel .slides .slide-15,
.fade-carousel .slides .slide-16,
.fade-carousel .slides .slide-17,
.fade-carousel .slides .slide-18,
.fade-carousel .slides .slide-19,
.fade-carousel .slides .slide-20,
.fade-carousel .slides .slide-21,
.fade-carousel .slides .slide-22,
.fade-carousel .slides .slide-23,
.fade-carousel .slides .slide-24,
.fade-carousel .slides .slide-25,
.fade-carousel .slides .slide-26,
.fade-carousel .slides .slide-27,
.fade-carousel .slides .slide-28,
.fade-carousel .slides .slide-29,
.fade-carousel .slides .slide-30,
.fade-carousel .slides .slide-31 {
  min-height: 600px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.fade-carousel .slides .slide-31 {
  /* for story and history pages cover */
   background-image: url(./assets/img/story.jpg);
}
.fade-carousel .slides .slide-21{
  background-image: url(./assets/img/history.jpg);

}
.fade-carousel .slides .slide-29 {
  /* for story and history pages cover */
  background-image: url(./assets/img/ceo.jpeg);
}
.fade-carousel .slides .slide-28 {
  /* for story and history pages cover */
  background-image: url(./assets/img/team.jpg); 
}
.fade-carousel .slides .slide-26 {
  /* for story and history pages cover */
  background-image: url(./assets/img/certificate.jpg
  );
}
#center .carousel-indicators {
  bottom: 80px;
  left: 50%;
}
.fade-carousel .carousel-indicators > li {
  background: #fff;
  border-color: #fff;
  width: 18px;
  height: 18px;
}
.fade-carousel .carousel-indicators > li.active {
  width: 18px;
  height: 18px;
  background: #8e1e22;
  border-color: #8e1e22;
}

.hero h1 {
  font-size: 80px;
}

/*********************center_home_end****************/

/*********************qual****************/

/*********************qual_end****************/

/*********************farm****************/
#farm {
  padding-top: 50px;
  padding-bottom: 40px;
}
.farm_2i {
  box-shadow: 0px 0px 7px 0px rgb(83 95 159 / 15%);
  padding: 15px;
}
.farm_1 h1 {
  font-size: 40px;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1.7px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .farm_1 h1 {
    font-size: 30px !important;
  }
}
.farm_2i h4 {
  font-family: "Pompiere", cursive;
  font-weight: bold;
  letter-spacing: 1.6px;
}
.farm_2i p {
  font-weight: normal;
  font-size: 14px;
}
/*********************farm_end****************/

/*********************prod****************/
#prod {
  background: #fbfdff;
  padding-top: 40px;
  padding-bottom: 40px;
}
.prod_1i2 h6 {
  display: inline-block;
  color: #fff;
  padding: 5px 15px;
  border-radius: 3px;
}
.prod_1i2 .bg_1 {
  display: inline-block;
  background: #8e1e22;
}
.prod_1i2 .bg_2 {
  display: inline-block;
  background: #8e1e22;
}
.prod_1i2 {
  position: absolute;
  padding: 15px;
  top: 0;
  right: 0;
}
.prod_1i {
  position: relative;
}
.prod_1ib h6 span {
  text-decoration: line-through;
  color: #999;
  margin-right: 10px;
}
.prod_1ib ul li {
  display: inline-block;
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
}
.prod_1ib ul li .bg_1 {
  background: #8e1e22;
  width: 50px;
  height: 50px;
  line-height: 48px;
  display: block;
  border-radius: 50%;
  color: #fff;
}
.prod_1ib ul li .bg_1:hover {
  background: #8e1e22;
}
.prod_1ib ul li .bg_2 {
  background: #8e1e22;
  width: 50px;
  height: 50px;
  line-height: 48px;
  display: block;
  border-radius: 50%;
  color: #fff;
}
.prod_1ib ul li .bg_2:hover {
  background: #8e1e22;
}
.prod_1ib {
  background: #fff;
  padding: 0px 15px 15px 15px;
}
.prod_1ib ul {
  display: none;
  visibility: hidden;
}
.prod_1im:hover .prod_1ib ul {
  display: block;
  visibility: visible;
}
.click p strong {
  font-size: 17px;
  font-weight: bold;
  font-family: "Poppins";
  text-align: justify;
}
.click .small {
  font-size: 14px;
}
/*********************prod_end****************/

/*********************choose****************/
#choose {
  padding-top: 40px;
  padding-bottom: 40px;
}
.choose_1l span {
  background: #8e1e22;
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  line-height: 80px;
  font-size: 50px;
  margin-bottom: 10px;
}
.choose_1l span:hover {
  background: #8e1e22;
}
/*********************choose_end****************/

/*********************sale****************/
#sale {
   background-image: url(./assets/img/homeback.jpg);
  background-position: center;
  
  
}
.sale_m {
  background: #0000008c;
  padding-top: 120px;
  padding-bottom: 120px;
  height: 480px;
}
#sale_o {
  /* background-image: url(./assets/img/fruits.png); */
  background-position: center;
}
/*********************sale_end****************/

/*********************about_h****************/
#about_h {
  padding-top: 40px;
  padding-bottom: 40px;
}
.about_h_1r ul li {
  display: inline-block;
}
.nav_1 > li.active > a,
.nav_1 > li.active > a:hover,
.nav_1 > li.active > a:focus {
  background: #8e1e22 !important;
  color: #fff !important;
}

.about_h_1r ul li a {
  background: #f2f9ff !important;
  color: #111 !important;
  font-size: 18px;
  margin-right: 2px;
  margin-left: 2px;
  border-radius: 15px;
  padding: 8px 12px;
  display: block;
  border: 1px solid #e2f2ff;
}
.about_h_1r ul li a:hover {
  background: #8e1e22 !important;
  color: #fff !important;
}
.about_h_1r ul {
  margin-bottom: 30px;
}
/*********************about_h_end****************/

/*********************team_h****************/
#team_h {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #fbfdff;
}
.team_h1i ul li a {
  background: #8d918b;
  border-radius: 0;
}
.team_h1i {
  background-color: #f3f0f0;

  border-radius: 8px;
  padding: 0 0 25px;
}
.team_h1i img {
  width: 100%;
  height: 300px;

  border-radius: 0;
}
.team_h1i p {
  padding: 10px;
}

.team_o {
  border-bottom: 1px solid #eee;
}

.team_o .team_h1 {
  margin-top: 30px;
}
/*********************team_h_end****************/

/*********************client****************/
#client {
  padding-top: 40px;
  padding-bottom: 40px;
}
.testim_1i2i img {
  float: left;
  margin-right: 15px;
  width: 70px;
  height: 70px;
  /* position: absolute; */
  /* top: calc(100% - 80px); */
  /* left: 35px; */
}
.testim_1i2i {
  margin-top: 20px;
}
.testim1 {
  background: #fbfdff;
  padding: 40px 20px;
  /* min-height: 370px; */
}
.popular_3 {
  margin-top: 30px;
}
.popular_3 .btn-success {
  background: #fff;
  border-radius: 0;
  padding: 0;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border: 1px solid #ddd;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  color: #333;
  border-radius: 50%;
  font-size: 20px;
}
.popular_3 .btn-success:hover {
  background: #8e1e22;
  border-color: #8e1e22;
  color: #fff;
}
/*********************client_end****************/

/*********************news_h****************/
#news_h {
  background: #fbfdff;
  padding-top: 40px;
  padding-bottom: 40px;
}
.news_h1l1 {
  /* background-image: url(./assets/img/29.jpg); */
  background-position: center;
}
.news_h1l2 {
  background: #0000008c;
  padding: 290px 30px 30px 30px;
}
.news_h1r {
  margin-top: 30px;
}
/*********************news_h_end****************/
@media screen and (max-width: 767px) {
  .hero {
    width: 100%;
    left: 50%;
    text-align: center;
  }
  .hero p {
    text-align: center;
  }
  .testim1 {
    text-align: center;
    margin-top: 10px;
  }
  .testim_1i2i img {
    float: none;
    margin-right: 0;
  }
  .hero h1 {
    font-size: 50px;
  }
  .main_2 {
    position: static;
  }
  #center .carousel-indicators {
    bottom: 10px;
  }
  #qual {
    padding-top: 40px;
  }
  #qual br {
    display: none;
  }
  #farm {
    padding-top: 40px;
  }
  .farm_2i {
    margin-top: 10px;
  }
  .prod_1im {
    margin-top: 10px;
  }
  .choose_1l {
    text-align: center;
  }
  .choose_1m {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #sale {
    text-align: center;
  }
  #sale br {
    display: none;
  }
  #about_h {
    text-align: center;
  }
  .about_h_1r {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .team_h1i {
    margin-top: 10px;
  }
  #sale_o br {
    display: none;
  }
  #news_h {
    text-align: center;
  }
  .news_h1rl {
    margin-top: 10px;
  }
  .enquiry_1 .input-group {
    width: 90%;
  }
  .qual_1 p {
    text-align: left;
  }
  .farm_2i p {
    text-align: center;
  }
  .choose_1l p {
    text-align: left;
  }
  .sale_1 p {
    text-align: center;
  }
  .about_h_1r ul li {
    margin-top: 8px;
  }
  .about_h_1r ul {
    text-align: left;
  }
  .about_h_1r p {
    text-align: left;
  }
  .team_h1i p {
    text-align: left;
  }
  .testim1 p {
    text-align: left;
  }
  .testim_1i2i img {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .testim1 {
    padding: 10px 10px;
  }
  .choose_1m img {
    min-height: 520px;
  }
  .choose_1l p {
    font-size: 16px;
  }
  .about_h_1l img {
    min-height: 420px;
  }
  .team_h1i ul li {
    margin-bottom: 5px;
  }
  .testim_1i2i img {
    width: 30px;
    height: 30px;
  }
  .testim_1i2i h4 {
    font-size: 20px;
  }
  .news_h1rl img {
    min-height: 200px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .choose_1m img {
    min-height: 520px;
  }
  .about_h_1l img {
    min-height: 420px;
  }
  .news_h1rl img {
    min-height: 200px;
  }
}
@media (min-width: 1201px) and (max-width: 1255px) {
}
/*********************benef****************/
#benef {
  padding-top: 50px;
  padding-bottom: 50px;
}
.benef_1l .col_1 {
  color: #8e1e22 !important;
  font-size: 20px;
  font-weight: bold;
}
.benef_1l h3 {
  font-family: "Goblin One", cursive;
  font-size: 24px;
  margin-bottom: 25px;
}
.benef_1l p {
  margin-bottom: 10px;
}
.award_1i {
  background-color: #3a3d41;
  padding: 20px;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .award_1i {
    border-radius: 50%;
    width: 275px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}
.award_1i span i {
  color: #fff;
}
.award_1i h1,
.award_1i h5 {
  font-family: "Goblin One";
  color: #fff !important;
}
.award_1i h1 {
  margin-top: 10px;
}
.award_1i h5 {
  font-size: 18px;
  font-family: "Raleway";
  margin-top: -25px;
}
@media (max-width: 768px) {
  .award_1i h1 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .award_1i h5 {
    margin-top: 20px;
  }
  .nav_1 {
    margin-left: 40px;
  }
}
/*********************benef_end****************/
.about_h_1r p,
.sale_1 p,
.farm_2i p {
  text-transform: none !important;
}
@media (max-width: 991px) {
  .about_h_1r p {
    text-align: center;
  }
}

@media (max-width: 768px) {
  #off .off1 .off1r h2 {
    font-size: 26px !important;
  }
}
#off .off1 .off1r p {
  text-transform: none !important;
}

@media (max-width: 768px) {
  .home-ar .new-products-figure,
  .new-products-figure {
    float: none !important;
    margin: auto;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  #benef .stats .col-md-3 {
    width: 260px;
    height: 200px;
    margin: 0 auto 15px auto;
  }
}
