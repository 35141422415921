.new-prod-ar .farm_1 h1 {
  letter-spacing: normal;
  word-spacing: normal;
  font-family: "cairo" !important;
  font-weight: 900;
}
.new-prod-ar .mgt.bg_1 {
  font-family: "cairo" !important;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
}
.new-prod-ar .prod_1ib {
  font-family: "cairo" !important;
}
