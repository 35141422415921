.header-ar {
  direction: rtl;
}
.header-ar #top .top_1l {
  margin-top: 23px;
}
.header-ar #top .top_1r {
  margin-top: 28px;
}
.header-ar #top .top_1l i {
  margin-left: 10px;
}
.header-ar #top .top_1l span {
  font-family: "cairo" !important;
}
.header-ar #top .top_1r .big span {
  font-family: "cairo" !important;
}

.header-ar #header ul li a {
  font-family: "cairo" !important;
  letter-spacing: normal;
  word-spacing: normal;
}
.header-ar #top .top_1m .logo {
  margin-top: 14px;
}

/*Start Media Query */
@media (min-width: 768px) {
  .header-ar #header .navbar .container .nav-holder ul:first-child {
    float: right;
    direction: rtl;
  }
  .header-ar #header .navbar .container .nav-holder ul:first-child > li {
    float: right;
  }
  .header-ar #header .navbar-header {
    float: right;
  }
}
@media (min-width: 992px) {
  .header-ar #header .navbar .container .nav-holder ul:first-child > li {
    margin-left: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1169px) {
  .nav-ar .navbar-header {
    direction: ltr;
    width: 22% !important;
  }
  .nav-ar .navbar-header .navbar-brand {
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .header-ar
    #header
    .navbar
    .container
    .nav-holder
    ul:first-child
    > li:first-of-type {
    margin-left: 8px;
  }
}
@media (max-width: 767px) {
  .header-ar #header .navbar-header button {
    float: left;
    margin-left: 25px;
  }
  .header-ar .logo-container img {
    max-height: 125% !important;
  }
  .nav-ar .navbar-header .navbar-brand {
    float: right;
    display: block !important;
    width: 240px !important;
    margin: 0 !important;
    padding: 0px !important;
    padding-right: 5px !important;
  }
  .nav-ar .navbar-header .navbar-brand img {
    margin: 0 !important;
    transform: translateY(-5px);
  }
}
