.media-modal {
  position: fixed;
  width: 550px;
  height: 98vh;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fefefe;
  transition: 0.6s;
  padding: 0 12px 10px 12px;
  box-shadow: 0 12px 20px 0 rgb(0 0 0 / 13%), 0 2px 4px 0 rgb(0 0 0 / 12%);
  z-index: 100000;
}
.media-modal.video-type {
  width: 700px;
  height: 75vh;
  top: 80px;
}

.media-modal .close-icon {
  font-size: 1.5rem;
  color: #777;
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  z-index: 2;
  cursor: pointer;
  transition: 0.3s;
}
.media-modal .close-icon:hover {
  transform: scale(1.15);
}

.media-modal .media {
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(100% - 29px);
}
.media-modal .media img {
  width: 100%;
  height: 100%;
}

/* Start Media Query */
@media (max-width: 767px) {
  .media-modal.video-type {
    width: 99%;
    height: 65vh;
    top: 100px;
  }
}
@media (max-width: 576px) {
  .media-modal {
    width: 99%;
    height: 80vh;
    top: 60px;
  }
  .media-modal.video-type {
    height: 50vh;
    top: 140px;
  }
}
