.contact-ar .center_o1 h1{
font-family: 'cairo' !important;
}
.contact-ar .center_o1 h4 a {
    font-family: 'cairo' !important;

}
.contact-ar .center_o1 h4 span{
    color: #000 !important;
    font-family: 'cairo' !important;
    font-size: 16px;
}
.contact-ar{
    direction: rtl;
}
.contact-ar #contact .contact_1 h4 , .contact-ar #contact .contact_1lm h4{
    font-family: 'cairo' !important;
    color: #000;
}
.contact-ar #contact .contact_1r1 p , .contact-ar #contact .contact_1lm h3{
    font-family: 'cairo' !important;
}
.contact-ar #contact .contact_1 .checkout_1l1 label {
    font-family: 'cairo' !important;
}
.contact-ar .error-message , .contact-ar .button{
    font-family: 'cairo' !important;
}
@media (max-width:768px) {
    .contact-ar #contact .contact_1r1 p{
        text-align: center !important;
    }
}