body {
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  scroll-behavior: smooth;
}
.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
:root {
  --main-Color: #2196f3;
  --hover-Color: #1787e0;
  --main-Transitions: 0.3s;
  --main-padding-top: 100px;
  --main-padding-bottom: 100px;
  --section-background: #ececec;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  color: #3a3d41;
  font-weight: bold;
}
a {
  color: #325976;
}
a:hover {
  text-decoration: none;
  color: #8e1e22;
}
a:focus {
  text-decoration: none;
  color: #8e1e22;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 15px;
}
li {
  font-size: 16px;
}
p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  margin-top: 15px;
  color: #333;
}
h1 {
  font-size: 50px;
  margin-top: 15px;
}
h2 {
  font-size: 42px;
  margin-top: 15px;
}
h3 {
  font-size: 34px;
  margin-top: 15px;
}
h4 {
  font-size: 24px;
  margin-top: 15px;
}
h5 {
  font-size: 18px;
  margin-top: 15px;
}
h6 {
  font-size: 16px;
  margin-top: 15px;
  font-weight: normal;
}
hr {
  border-top: 1px solid #e3f0fb;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 10px 0;
}
.space_left {
  padding-left: 0;
}
.space_right {
  padding-right: 0;
}
.space_all {
  padding: 0;
}
.iw {
  width: 100%;
}

.button_1 {
  background: #8e1e22 !important;
  display: inline-block;
  color: #fff;
  padding: 15px 25px 15px 25px !important;
  margin-top: 10px;
  font-weight: normal;
  border-radius: 3px;
  font-size: 14px;
}
.button_1:hover {
  background: #882024 !important;
  color: #fff;
}
.button_1 i {
  margin-left: 5px;
  margin-right: 5px;
}

.button {
  background-color: #8e1e22;
  display: inline-block;
  padding: 15px 25px 15px 25px !important;
  margin-top: 10px;
  font-weight: normal;
  border-radius: 3px;
  transition: 0.5s ease-in;
  color: #fff;
  font-family: "Exo 2", sans-serif;
  font-size: 15px;
}
.button:focus,
.button:hover {
  background-color: #fff;
  color: #8e1e22;
}
.newbutton {
  background: #8e1e22;
  display: inline-block;
  color: #fff;
  padding: 15px 25px 15px 25px !important;
  margin-top: 10px;
  font-weight: normal;
  border-radius: 3px;
  font-size: 14px;
  font-family: "Exo 2", sans-serif !important;
  transition: 0.5s ease-in;
}
.newbutton:hover {
  background: #fff;
  color: #8e1e22;
}

.button i {
  margin-left: 5px;
  margin-right: 5px;
}
.border_none {
  border: none !important;
}
.mgt {
  font-family: "Exo 2", sans-serif !important;
  margin-top: 5px;
}
@media (max-width: 768px) {
  .fa.fa-bolt {
    margin-top: 50px;
  }
}

#footer .footer-top .footer-contact p span {
  text-transform: lowercase !important;
}
#footer .footer-top .footer-contact p a{
  color: #FFF !important;
}

#menu1 h4,
#menu2 h4 {
  color: #000;
}
.tab-content #home h4 {
  color: #111 !important;
}
.mgb {
  margin-bottom: 0 !important;
}
.mgl {
  margin-left: 0 !important;
}
.bold {
  font-weight: bold !important;
}
.normal {
  font-weight: 100 !important;
}
.navbar {
  min-height: auto;
  margin-bottom: 0;
  border: none;
  border-radius: 0;
}
.col {
  color: #fff !important;
}
.col_1 {
  color: #8e1e22;
}

.col_2 {
  color: #999 !important;
}
.col_3 {
  color: #8e1e22;
  font-family: "Bad Script", cursive;
}
.text-white {
  font-family: "Exo 2", sans-serif !important;
  margin-top: 75px;
  margin-bottom: 50px;
  text-transform: capitalize;
}
@media (max-width: 768px) {
  .text-white {
    font-size: 30px;
    margin-top: 75px;
    margin-bottom: -50px;
  }
}
.service_h1 .col_3 {
  font-size: 15px;
  color: #1b1a24;
  line-height: 1.8;
  font-weight: 300;
  word-spacing: 1.5px;

  font-family: "Exo 2", sans-serif;
}

@media (max-width: 767px) {
  .col_3 {
    color: #fff;
  }
  .service_h1 .col_3 {
    color: #444;
    letter-spacing: normal;
    text-align: center;
    padding: 5px 0px;
  }
}
.ceopa {
  text-transform: none !important;
  line-height: 1.6;
  color: #666;
  font-weight: normal;
}
@media (max-width: 768px) {
  .ceopa {
    letter-spacing: normal;
    word-spacing: normal;
    text-align: left;
    padding: 5px 0;
  }
}

.contacth {
  color: #3a3d41 !important;
}
.mgt.mail {
  text-transform: lowercase !important;
}
.ceopa .wstyle {
  text-transform: uppercase !important;
  font-size: 18px;
}

.new {
  padding: 10px;
  width: 300px;
  font-family: "Exo 2", sans-serif;

  font-size: 3.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff !important;
  background-image: linear-gradient(
    to right,
    rgba(233, 228, 228, 0),
    rgb(160, 150, 150)
  );
}
@media (max-width: 767px) {
  .new {
    margin-top: 90px;
    margin-left: -50px;
  }
}
.col_4 {
  color: #ddd !important;
}
.col_5 {
  color: #8e1e22 !important;
}
.col_6 {
  color: #8e1e22 !important;
  font-family: "Bad Script", cursive;
}
.newFont {
  max-width: 650px;
  margin: 25px auto 5px;
  font-family: "Exo 2", sans-serif;
}

@media (max-width: 767px) {
  .newFont {
    margin: 0;
    font-size: 18px;
    max-width: 300px;
    margin: 15px auto 5px;
    text-align: center;
  }
}

.pdb {
  padding-bottom: 0 !important;
}
.block {
  display: block;
}
.inline {
  display: inline-block;
}
.bgn {
  background: none !important;
}
.big {
  text-transform: uppercase;
}
.head14 {
  font-size: 25px;
}
.headSlider {
  font-family: "Exo 2", sans-serif;
}
@media (max-width: 767px) {
  .headSlider {
    font-size: 18px;
  }
}
.hr_1 {
  border-color: #8e1e22;
  border-width: 5px;
  width: 70px;
}
.font_16 {
  font-size: 16px;
}
.line_1 {
  line-height: 1.4;
}
/*********************header****************/
#top {
  padding-top: 8px;
  padding-bottom: 8px;
}
.top_1l {
  padding-top: 28px;
}
.top_1r {
  padding-top: 11px;
}
.top_1m {
  padding-top: 3px;
}
.top_1l p i {
  margin-right: 5px;
}

@media (max-width: 767px) {
  .mgt img {
    margin-bottom: 15px;
  }
  .mgt {
    font-size: 22px;
  }
}

#header {
  background: #fff;
  padding-top: 10px;
  padding-bottom: 7px;
}
.m_tag {
  padding: 10px 15px;
  color: #4e3914;
  font-size: 13.5px !important;
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.5s ease-in;
  font-family: "Exo 2", sans-serif;
}

#header .m_tag:hover {
  color: #fff;
  background: #8e1e22;
  border-radius: 7px;
}

#header .active_tab {
  background-color: #8e1e22;
  border-radius: 7px;
  color: #fff;
  width: 150px;
  letter-spacing: 1.9px;
  text-align: center;
  margin-left: -15px;
}

#header .drop_3 a {
  font-size: 13.5px;
  border-bottom: 1px solid #eee;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: capitalize;
  letter-spacing: 1.3px;
  font-family: "Exo 2", sans-serif;
  margin-left: -10px;
}
@media (max-width: 767px) {
  #header .drop_3 a {
    font-size: 8px !important;
  }
}
#header .drop_3 a:hover {
  background: #8e1e22;
  color: #fff;
}
#header .drop_3 {
  padding: 3px;
  min-width: 200px;
  margin-top: 8px;
  border-radius: 0;
}

.drop_1 {
  min-width: 320px;
  left: auto;
  right: 0;
  padding: 20px 15px;
  margin-top: 8px !important;
  border-radius: 0;
}
.drop_1i {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.drop_1i1l span i {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 27px;
  border: 1px solid #eee;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}
.drop_1i1l span i:hover {
  background: #8e1e22;
  color: #fff;
  border-color: #8e1e22;
}
.drop_1i1 {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.drop_1i2 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.drop_1i3 .button {
  display: block;
}
.modal-dialog {
  margin-top: 250px;
  background: #333333e8;
  padding: 100px;
  width: 60%;
}
.modal-body {
  padding: 0;
}
.modal-body .form-control {
  height: 56px;
  font-size: 18px;
  border: none;
  background: #111;
  box-shadow: none;
  border-radius: 0;
  color: #ddd;
}
.modal-body .input-group .btn {
  background: #8e1e22;
  margin-left: 0 !important;
  padding: 15px 20px;
  font-size: 18px;
  border-radius: 0;
  border: none;
}
.modal-body .input-group .btn:hover {
  background: #8e1e22;
}

/*
.cd-secondary-nav {
  position: static;
}
.cd-secondary-nav .is-visible {
visibility: visible;
transform: scale(1);
transition: transform 0.3s, visibility 0s 0s;
}
*/

.cd-secondary-nav.is-fixed {
  z-index: 9999;
  position: fixed;
  left: auto;
  top: 0;
  width: 100% !important;
  background: #fff !important;
}
.cd-secondary-nav.is-fixed {
  top: -40px;
  transform: translateY(40px);
  transition: transform 0.3s;
}
/*********************header_end****************/

/*********************common****************/
.pages {
  margin-top: 20px;
}
.pages ul li {
  display: inline-block;
  margin: 0 2px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: #f56505;
  text-align: center;
  border-radius: 3px;
}
.pages ul li a {
  display: block;
  color: #333;
}
.pages ul li:hover {
  background: #8e1e22;
}
.pages ul li:hover a {
  color: #fff;
}
.act {
  background: #8e1e22 !important;
}
.act a {
  color: #fff !important;
}

/**************** Start footer *****************/
#footer {
  background-color: #f0e5e5 !important;
  padding: 0 0 30px 0;
  color: #8e1e22;
  font-size: 14px;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #3a3d41;
  font-family: "Exo 2", sans-serif;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 24px;
  margin: 0 0 15px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  font-family: "Exo 2", sans-serif;
  color: #fff;
}

#footer .footer-top .footer-contact h3 span {
  color: #8e1e22;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  color: #eee7e7;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top p {
  font-size: 15px;
  line-height: 24px;
  margin-left: 5px;
  font-family: "Roboto", sans-serif;
  color: #d1c2c2;
  margin-bottom: 20px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #fff;
  font-size: 14px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
#footer .footer-top .footer-links ul li {
  padding-right: 30px;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #fa4c52;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #3a3d41;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.2s;
}
#footer .footer-top .social-links .twitter {
  background: #33ccff;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top .social-links .facebook {
  background: #3b5998;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top .social-links .instagram {
  background: linear-gradient(
    29.61deg,
    #f38334 0%,
    #da2e7d 50.39%,
    #6b54c6 100%
  );
  color: #fff;
  text-decoration: none;
}
#footer .footer-top .social-links .youtube {
  background: #CD201F;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top .social-links .linkedin {
  background: #007bb7;
  color: #fff;
  text-decoration: none;
}

#footer .footer-top .social-links .twitter:hover {
  background: #49ccf8;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top .social-links .facebook:hover {
  background: #456099;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top .social-links .instagram:hover {
  background: linear-gradient(
    29.61deg,
    #ce814a 0%,
    #d14586 50.39%,
    #7061aa 100%
  );
}
#footer .footer-top .social-links .google-plus:hover {
  background: #a8442e;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top .social-links .linkedin:hover {
  background: #25749b;
  color: #fff;
  text-decoration: none;
}

#footer .copyright {
  margin-top: 37px;
  float: left;
}

#footer .credits {
  float: right;
  text-align: center;
  font-size: 13px;
  color: #8e1e22;
  margin-top: 37px;
}
#footer .credits a {
  color: #8e1e22;
  font-size: 14px;
  transition: 0.5s;
  margin-left: 10px;
  font-weight: bold;
}
#footer .credits a:hover {
  color: #d65e5e;
}
@media (max-width: 768px) {
  #footer .copyright,
  #footer .credits {
    float: none;
    text-align: center;
    padding: 2px 0;
  }
  #footer .credits {
    margin-top: -5px;
  }
}
/**************** end footer *****************/

.center_o {
  padding-top: 70px;
  padding-bottom: 70px;
  background: #fbfdff;
}
.center_o a {
  color: #8e1e22;
}

.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

figure.effect-jazz figcaption::after,
figure.effect-jazz img {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}
figure.mainimage {
  width: 400px;
  height: 300px;
  margin-top: 150px;
}
@media (max-width: 991px) {
  figure.mainimage {
    margin-top: 0;
  }
}
figure.mainimage img {
  width: 400px;
  height: 300px;
}
figure.effect-jazz:hover img {
  -webkit-transform: scale3d(1.05, 1.05, 1);
  transform: scale3d(1.05, 1.05, 1);
}
.farm_1 {
  margin-bottom: 30px;
}
/*********************common_end****************/

/*********************enquiry****************/
#enquiry {
  padding-top: 60px;
  padding-bottom: 60px;
}
.enquiry_1 .input-group {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.enquiry_1 .input-group .form-control {
  border-radius: 30px 0 0 30px;
  height: 56px;
  font-size: 16px;
  box-shadow: none;
  border-right: none;
  border-color: #eee;
}
.enquiry_1 .input-group .btn {
  margin-left: 0 !important;
  background: #8e1e22;
  border-color: #8e1e22;
  padding: 15.4px 30px;
  font-size: 16px;
  border-radius: 0 30px 30px 0;
}
.enquiry_1 .input-group .btn:hover {
  background: #8e1e22;
  border-color: #8e1e22;
}
/*********************enquiry_end****************/

@media screen and (max-width: 767px) {
  .space_left {
    padding-left: 15px;
  }
  .space_right {
    padding-right: 15px;
  }
  .space_all {
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar-collapse {
    box-shadow: none;
  }
  .navbar .navbar-collapse,
  .navbar-default .navbar-form {
    border-color: #eee;
    margin-top: 15px;
  }
  .navbar-toggle {
    background: #8e1e22;
    border: none;
    border-radius: 0;
    margin-top: 8px;
  }
  .navbar-toggle:hover {
    background: #8e1e22 !important;
  }
  .navbar-toggle:focus {
    background: #8e1e22 !important;
  }
  .navbar-toggle .icon-bar {
    background: #fff !important;
  }

  .dropdown-menu {
    background: #fff !important;
  }
  #header .drop_3 a {
    font-size: 13px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-family: "Exo 2", sans-serif;
  }
  #header .drop_3 {
    margin-top: 0;
  }
  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus {
    border: none;
  }
  #header .m_tag {
    border: none;
    border-bottom: 1px solid #eee;
    padding-top: 14px !important;
    padding-bottom: 10px !important;
    margin-top: 0;
    border-radius: 0 !important;
    border-right: none;
    margin: 0;
    letter-spacing: 1.9px;
    text-align: justify;
    font-family: "Exo 2", sans-serif;
    width: 100%;
    font-weight: 400;
  }
  .pages ul li {
    margin-bottom: 5px;
  }
  .hr_1 {
    margin-left: auto !important;
  }

  .modal-dialog {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    padding: 20px;
  }
  .top_1m {
    display: none;
  }
  #top {
    text-align: center;
    padding-bottom: 20px;
  }
  .top_1r {
    text-align: center;
    margin-top: 10px;
  }
  .drop_1 {
    min-width: 100%;
  }
  .drop_1il {
    text-align: center;
    margin-top: 10px;
  }
  .drop_1i1r {
    margin-top: 10px;
    text-align: center;
  }
  .enquiry_1 .input-group {
    width: 90%;
  }
  .enquiry_1 p {
    text-align: left;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .top_1m h3 {
    font-size: 18px;
  }
  .top_1l p {
    font-size: 13px;
  }
  .m_tag {
    padding: 15px 10px !important;
  }

  #header .drop_3 {
    left: auto !important;
    right: 0 !important;
  }
  #header .drop_3 a {
    font-size: 13px;
  }

  .button {
    padding: 15px 5px 15px 5px !important;
  }
}
@media (min-width: 841px) and (max-width: 991px) {
  .cd-secondary-nav.is-fixed .m_tag {
    padding: 15px 10px !important;
    font-size: 15px;
  }
  #header .drop_3 a {
    font-size: 15px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .top_1m h3 {
    font-size: 26px;
  }
  .cd-secondary-nav.is-fixed .m_tag {
    padding: 15px 10px !important;
  }
  #header .drop_3 {
    left: auto !important;
    right: 0 !important;
  }
}

/*********************inform****************/
#inform {
  padding-top: 40px;
  padding-bottom: 80px;
}
.inform_1l h2 {
  font-size: 38px;
  font-family: "Tahoma", cursive;
  letter-spacing: 1.7px;
  text-align: justify;
}

.inform_1l p {
  margin-left: 5px;
}

.inform_1r {
  position: relative;
}
.inform_1ri1 {
  position: absolute;
  bottom: -40px;
  right: 40px;
  background: #fbfdff !important;
  padding: 20px 30px;
  width: 80%;
}
.inform_1ri1 h4 {
  color: #3a3d41 !important;
  font-size: 18px;
  font-family: "Exo 2", sans-serif !important;
  text-transform: none;
}
.inform_1ri img {
  width: 400px;
  height: 400px;
  margin-top: 50px;
  margin-left: 100px;
}
@media (max-width: 767px) {
  .inform_1ri img {
    margin: 0;
    width: 100%;
  }
}
.newstyle {
  line-height: 1.5;
  font-size: 13.5px;
}
/*********************inform_end****************/

/*********************about_page_end****************/

@media screen and (max-width: 767px) {
  #inform {
    padding-bottom: 40px;
  }
  .inform_1ri1 {
    position: static;
    width: 100%;
  }
  .inform_1r {
    margin-top: 15px;
  }
  #about_page {
    text-align: center;
  }
  .trend_1i {
    margin-top: 10px;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .trend_1ir p {
    font-size: 15px;
  }
  .trend_1ir {
    padding: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .trend_1ir p {
    font-size: 16px;
  }
}

/*-------------------------------------------------------------------*/
.story-box {
  padding: 70px 0px;
}

.story-box .title-box h3 {
  font-size: 30px;
  margin-bottom: 25px;
  font-family: "Tahoma";
}

.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.main-timeline-box {
  position: relative;
  word-wrap: break-word;
}

.main-timeline-box .timeline-element {
  margin-bottom: 50px;
  position: relative;
  word-wrap: break-word;
  word-break: break-word;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
}
.main-timeline-box .reverse {
  flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
}
.main-timeline-box .separline::before {
  top: 20px;
  bottom: 0;
  position: absolute;
  content: "";
  width: 2px;
  background-color: #f1f1f1;
  left: calc(50% - 1px);
  height: calc(100% + 4rem);
}

.main-timeline-box .iconBackground {
  position: absolute;
  left: 50%;
  width: 20px;
  height: 20px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  font-size: 30px;
  display: inline-block;
  background-color: #8e1e22;
  top: 20px;
  margin-left: -10px;
}
.main-timeline-box .timeline-text-content {
  padding: 30px 35px;
  background: #f1f1f1;
}
.main-timeline-box .reverse .timeline-text-content {
  margin-right: 30px;
}
.main-timeline-box .reverse .time-line-date-content p {
  float: left;
  padding: 30px 35px;
  background: #f1f1f1;
}
.main-timeline-box .reverse .time-line-date-content {
  margin-right: 30px;
}

.display-font {
  font-family: "Marck Script", cursive;
  font-size: 30px;
  color: #222222;
}
.main-timeline-box .timeline-text-content {
  margin-left: 30px;
}

.main-timeline-box .time-line-date-content p {
  float: right;
  padding: 30px 35px;
  background: #f1f1f1;
}
.main-timeline-box .time-line-date-content {
  margin-right: 30px;
}

.main-timeline-box .time-line-date-content .mbr-timeline-date {
  background: #8e1e22;
  color: #ffffff;
  width: 150px;
  font-size: 18px;
  padding: 10px 2px;
  text-align: center;
}
.main-timeline-box .reverse .time-line-date-content .mbr-timeline-date {
  background: #424e3f;
  color: #ffffff;
  width: 150px;
  font-size: 18px;
  padding: 10px 2px;
  text-align: center;
}

/*------------------------------------------------------------------*/

.team_h1ir {
  background: #fff;
  padding: 20px;
  min-height: 320px;
}
.team_h1ir p {
  font-size: 18px;
}
.team_h1ir ul li a {
  background: #404a3d;
}
.project_m {
  background: #f7f5f5;
  padding-top: 50px;
  padding-bottom: 50px;
}

.block_icon {
  color: #8e1e22;
  display: block;
  font-size: 36px;
  line-height: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.block_icon:before {
  display: inline-block;
  width: 6%;
  height: 2px;
  background-color: #8e1e22;
  vertical-align: middle;
  margin-right: 1%;
  content: "";
}
.block_icon:after {
  display: inline-block;
  width: 6%;
  height: 2px;
  background-color: #8e1e22;
  vertical-align: middle;
  margin-left: 1%;
  content: "";
}
/*********************about_end****************/
@media (max-width: 767px) {
  .story-box .timeline-element {
    display: flex;
    flex-direction: column;
  }
  .story-box .title-box h3 {
    text-align: center;
  }
  .story-box .timeline-element .time-line-date-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
  }
  .story-box .timeline-element .timeline-text-content {
    display: flex;
    justify-content: center;
    text-align: center;
    max-width: 300px;
    margin: 10px auto;
  }

  .main-timeline-box .iconBackground {
    display: none;
  }
}

/*-------------------------------------------------------------- */

/*********************special****************/
#special {
  padding-top: 40px;
  padding-bottom: 40px;
}
#exchange .special_1l img {
  width: 100%;
  height: 400px;
  border-radius: 10px;
}
#exchange .special_1l h3 {
  text-align: left;
}
@media (max-width: 767px) {
  #exchange .special_1l.newstylesection {
    margin-top: 40px;
  }
}
@media (max-width: 575px) {
  #exchange .special_1l img {
    height: 300px !important;
  }
}
.special_1r h3 {
  font-size: 25px;
}

/*********************special_end****************/

/*********************environment****************/
#environment {
  padding-top: 40px;
  padding-bottom: 20px;
  background: #009a4e14;
}

.environment_1 span i {
  font-size: 60px;
}
.environment_2i {
  background: #fff;
  padding: 30px 15px;
}
.environment_2 {
  margin-top: 20px;
}
.environment_2i h4 {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 1.7px;
}
/*********************environment_end****************/

@media screen and (max-width: 767px) {
  #exchange,
  #exchange1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
  }

  #exchange .special_1r,
  #exchange1 .special_1r {
    text-align: justify;
    padding: 10px;
    margin: 30px 10px 10px 0;
  }
  #exchange .special_1r h3,
  #exchange1 .special_1r h3 {
    margin: 0;
    padding: 0;
    font-size: 17px;
  }
  #exchange .special_1r p {
    margin: 15px auto;
  }

  #exchange .special_1l {
    display: block;
    margin: 10px 10px 10px 0;
  }
  #exchange .special_1l img {
    width: 100%;
  }
  .environment_1 h1 {
    font-size: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .inform_1ri1 {
    left: -20px;
  }
}
hr.style14 {
  border: 0;
  height: 1px;
  background-image: -webkit-linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -moz-linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -ms-linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -o-linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: linear-gradient(to left, #f0f0f0, #8c8b8b, #f0f0f0);
}
/*********************type****************/

.type_m {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Black background with opacity */
  z-index: 2;
  padding-top: 50px;
  padding-bottom: 50px;
  height: 500px;
  width: 1600px;
}
@media (max-width: 767px) {
  .type_m {
    width: 100%;
  }
}

#type {
  /* background-image: url(./assets/img/about3.png); */
  background-position: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.type_1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .type_1 {
    position: relative;
    margin: 0;
    padding: 0;
  }
}
.type_1 span {
  position: absolute;
  margin-top: 12%;
  margin-left: 36%;
  text-align: center;
  background: #8e1e22;
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 30px;
  display: block;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: 0.5s ease;
}
.type_1 span a {
  color: #fff;
}
@media (max-width: 767px) {
  .type_1 span {
    margin: 0;
    margin-top: 150px;
    margin-left: -40px;
  }
}
.type_1 span:hover {
  background: #313030;
  color: #fff;
  border-radius: 25px;
}
.type_1 h2 {
  position: absolute;
  text-align: center;
  margin-top: 25%;
  margin-left: 4%;
  color: #fff;
}

@media (max-width: 767px) {
  .type_1 h2 {
    margin: 0;
    margin-top: 280px;
    width: 300px;
    margin-left: -138px;
  }
}

@media (max-width: 767px) {
  .type_1 h2 {
    font-size: 24px;
  }
  .type_1 br {
    display: none;
  }
}
/*********************end type****************/

/*********************serv****************/
#serv {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #f5f0e9;
  margin-top: 40px;
}
.serv_2i {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
  background-color: none !important;
}
.serv_1 h2 {
  font-family: "Goblin One", cursive;
  font-weight: bold;
  line-height: 1.2;
  font-size: 36px;
}
.serv_2i1 {
  background: #fff;
  padding: 15px 20px 25px 20px;
}
.serv_2i1 p {
  font-size: 12px;
  text-align: center;
}

@media (max-width: 767px) {
  .serv_2i1 {
    width: 270px;
    margin: 0 auto;
  }
}
.serv_2i2i1 span a:hover {
  background: #404a3d;
  color: #fff;
}
.serv_2i2i1 {
  left: 20px;
  top: -25px;
}
.serv_2i1 h4 {
  font-size: 22px;
}

#serv span .col_4 {
  color: #8e1e22 !important;
}

.grid figure {
  position: relative;
  float: right;
  overflow: hidden;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
figure.effect-jazz figcaption::after,
figure.effect-jazz img {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-jazz:hover img {
  -webkit-transform: scale3d(1.05, 1.05, 1);
  transform: scale3d(1.05, 1.05, 1);
}
figure.effect-jazz.certiimage img {
  width: 250px !important;
}
@media (max-width: 767px) {
  #serv {
    margin-top: 0;
  }
  .serv_2i {
    margin-top: 15px;
  }
  .serv_2i1 h4 {
    font-size: 20px;
  }
}
/*********************serv_end****************/
[data-quantity] {
  position: relative;
  width: 100%;
  max-width: 11rem;
  padding: 0;
  margin: 0;
  border: 0;
}

[data-quantity] legend {
  display: none;
}

[data-quantity] input {
  font-size: 18px;
  height: 4rem;
  padding: 0 4rem;
  border-radius: 2rem;
  border: 0;
  background: #fff;
  color: #222;
  box-shadow: 0 10px 65px -10px rgba(0, 0, 0, 0.25);
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  font-weight: lighter;
}

[data-quantity] input:focus {
  outline: none;
  box-shadow: 0 5px 55px -10px rgba(0, 0, 0, 0.2), 0 0 4px #3fb0ff; /* Allows border radius on focus */
}

[data-quantity] input[type="number"]::-webkit-inner-spin-button,
[data-quantity] input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[data-quantity] button {
  position: absolute;
  width: 2.8rem;
  height: 2.8rem;
  top: 0.6rem;
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  background: #fff
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="50"><path d="M17 25h16M67 25h16M75 17v16" fill="none" stroke="rgb(59,69,66)" stroke-width="1.5px" stroke-linecap="round" stroke-linejoin="round" /></svg>')
    no-repeat 0 0;
  background-size: 5.6rem 2.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
  border-radius: 1.4rem;
  cursor: pointer;
  transition: opacity 0.15s;
  opacity: 0.5;
}

[data-quantity] button:active {
  background-position-y: 1px;
  box-shadow: inset 0 2px 12px -4px #c5d1d9;
}

[data-quantity] button:focus {
  outline: none;
  color: #fff;
}

[data-quantity] button:hover {
  opacity: 1;
}

[data-quantity] button.sub {
  left: 0.6rem;
}

[data-quantity] button.add {
  right: 0.6rem;
  background-position-x: -2.8rem;
}

/* Start loading spinner  */
.fetching-data-spinner-parent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fetching-data-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #8e1e22;
  border-radius: 50%;
  margin-left: 15px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* End loading spinner  */

.ceo {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}
.ceo .container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.ceo .container > * {
  flex-basis: calc(33.33% - 30px);
  max-width: calc(33.33% - 30px);
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .ceo .container > * {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.ceo .container .box {
  position: relative;
}

.ceo .container .box::before,
.team .container .box::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-color: #f3f3f3;
  border-radius: 10px;
  transition: var(--main-Transitions);
}
.ceo .container .box::before {
  width: calc(100% - 60px);
  z-index: -2;
}

.ceo .container .box::after {
  width: 0;
  z-index: -1;
  background-color: #e4e4e4;
}
.ceo .container .box:hover::after {
  width: calc(100% - 60px);
}
.ceo .container .box .data {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}
.ceo .container .box .data img {
  width: calc(100% - 30px);
  transition: var(--main-Transitions);
  border-radius: 10px;
}
.ceo .container .box:hover img {
  filter: grayscale(100%);
}
.ceo .container .box .data .links {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.ceo .container .box .data .links a {
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ceo .container .box .data .links i {
  color: #777;
  transition: var(--main-Transitions);
}
.ceo .container .box .data .links a:hover i {
  color: #8e1e22;
}
.ceo .container .box .info {
  text-transform: capitalize;
}

.ceo .container .box .info h3 {
  font-size: 22px;
  margin-bottom: 0;
  color: #8e1e22;
  transition: var(--main-Transitions);
}
.ceo .container .box:hover .info h3 {
  color: #777;
}
.ceo .container .box .info p {
  margin-top: 10px;
  margin-bottom: 25px;
  text-align: center;
}
.service_h1 .col_3 {
  text-align: center !important;
}
#exchange .special_1r p {
  text-align: left;
}
@media (max-width: 768px) {
  #exchange .special_1l h3 {
    margin-top: 25px;
    margin-bottom: -50px;
    text-align: center;
  }
  #exchange .special_1r p {
    text-align: center;
  }
}
