.product-ar .main {
  direction: rtl;
}
.product-ar .news.categoryname {
  direction: rtl;
  display: block;

  font-family: "cairo" !important;
  padding: 10px;
  width: fit-content;
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff !important;
  background-image: linear-gradient(
    to right,
    rgba(233, 228, 228, 0),
    rgb(160, 150, 150)
  );
  margin-right: -600px;
}

@media (max-width: 1385px) {
  .product-ar .news.categoryname {
    margin-right: -90%;
  }
}
@media (max-width: 768px) {
  .product-ar .news.categoryname {
    margin-right: 0;
    font-size: 24px;
  }
}
