.body_section {
    min-height: 400px;
    padding: 20px 0;
    overflow: hidden;
    position: relative;
  }



  .gallary_home .sec_title {
    margin-bottom: 70px;
    margin-top: 70px;
  }
  .center_view h3 {
    text-align: center;
    font-family: 'cairo' !important;
  }
h4{
    color: #FFF;
}  
  .center_view .float_text {
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
  }
  .sec_title .float_text {
    position: absolute;
    left: 0;
    top: -14px;
    font-size: 85px;
    color: #f5f5f5;
    font-weight: 700;
    text-transform: uppercase;
    right: 0;
    display: inline-block;
  }
  .animate-box {
    position: relative;
    opacity: 0;
    transition: all 1.1s ease-in-out;
    -o-transition: all 1.1s ease-in-out;
    -moz-transition: all 1.1s ease-in-out;
    -webkit-transition: all 1.1s ease-in-out;
  }
  
  .animate-box.animated {
    opacity: 1;
  }
  
  .animated {
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
    animation-delay: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  @-webkit-keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .fadeInUp,
  .fadeInLeft,
  .fadeInRight {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
  }
  /***/
  
  @-webkit-keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  /***/
  
  @-webkit-keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  .pop_img img {
    height: 300px;
    width: 100%;
    border-radius: 15px;
  }
  
  .pop_img {
    overflow: hidden;
    position: relative;
    height: 300px;
    margin: 10px 0;
  }
  .pop_img:hover .pop_over {
    opacity: 1;
    transform: translateX(0%);
    visibility: initial;
  }
  .play_vid {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    cursor: pointer;
    animation: pulse_two 1s infinite;
  }
  
  @-webkit-keyframes pulse_two {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    }
    70% {
      -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 1);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    }
  }
  
  @keyframes pulse_two {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }
    70% {
      -moz-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  .pop_over {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(142 30 34 / 30%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: 0.5s;
    transform: translateX(-200%);
    visibility: hidden;
    text-align: center;
    padding: 10px;
  }
  
  .pop_over a {
    font-size: 30px;
    margin-top: 12px;
  }
  .pop_video h4 {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 12px;
    text-align: center;
    margin: 0;
    color: #808185;
    font-family: 'cairo' !important;

  }
  
  .pop_video a {
    position: absolute;
    top: 50%;
    right: 0px;
    left: 0px;
    margin: auto;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #036e2d;
    transform: translateY(-50%);
    font-size: 18px;
  }
.video-carousel .item img {
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
  }
  
  .video-carousel .item .pop_video h4 {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 20px;
    background-color: rgb(83 88 90);
    padding: 12px;
    text-align: center;
    margin: 0;
    color: #ffffff;
    border-radius: 0 0 30px 30px;
  }
  
  .video-carousel .owl-nav {
    width: 100%;
    float: left;
    text-align: center;
    display: block !important;
  }
  
  .video-carousel .owl-nav .owl-prev,
  .video-carousel .owl-nav .owl-next {
    margin: 15px 3px 0 3px;
    width: 32px;
    height: 32px;
    display: inline-block;
    font-size: 25px;
    color: #bfbfbf;
    line-height: 32px;
    border: 1px solid #ccc;
    background: none;
  }