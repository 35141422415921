.footer-ar {
  direction: rtl;
}
.footer-ar .footer-top h3 {
  font-family: "cairo" !important;
}

.footer-ar .footer-links ul li {
  padding-right: 0 !important;
}
.footer-ar .footer-top h4 {
  font-family: "cairo" !important;
}
.footer-ar .footer-top p {
  font-family: "cairo" !important;
}
.footer-ar .footer-links li {
  font-family: "cairo" !important;
}
.footer-ar .container.py-4 {
  margin-top: -15px;
  font-family: "cairo";
}

.footer-ar .credits a {
  margin-right: 10px;
}
.footer-ar .credits {
  float: left !important;
}
.footer-ar .copyright {
  float: right !important;
}
.footer-ar .copyright span {
  margin-right: 5px;
}

/* Start Media Query */
@media (min-width: 992px) {
  .footer-ar .footer-top .container .row div {
    float: right;
  }
}
@media (max-width: 768px) {
  .footer-ar .credits {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .footer-ar .copyright {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
}
.footer-ar .footer-top .footer-contact.newcontact h3,
.footer-ar .footer-top .footer-contact.newcontact p {
  text-align: right !important;
}
