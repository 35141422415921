.story-ar {
  direction: rtl;
  font-family: "cairo";
}
.story-ar .newstyle {
  font-size: 16px;
}
.story-ar .new {
  display: block;
  margin-right: -600px;
  text-align: right;
  font-family: "cairo";
}
.story-ar .inform_1ri1 h4,
.story-ar .inform_1ri1 h5 .newbutton {
  font-family: "cairo" !important;
}

@media (max-width: 1385px) {
  .story-ar .new {
    margin-right: -90%;
  }
}
@media (max-width: 860px) {
  .story-ar .inform_1ri img {
    width: 360px;
  }
}
@media (max-width: 768px) {
  .story-ar .new {
    margin-right: 0;
  }
}

.story-ar .inform_1l h2 {
  letter-spacing: normal;
  word-spacing: normal;
  font-family: "cairo" !important;
}
.story-ar .mgt {
  font-family: "cairo" !important;
}
.story-ar .center_1_left_inner h4 a {
  font-family: "cairo" !important;
}
.story-ar .center_1_left_inner .innerpa,
.story-ar .center_1_left_inner .classic li {
  font-family: "cairo" !important;
  text-align: center;
}
