/*
Template Name: Dairy Farm
File: Layout CSS
Author: TemplatesOnWeb
Author URI: https://www.templateonweb.com/
Licence: <a href="https://www.templateonweb.com/license">Website Template Licence</a>
*/
/*********************about****************/
#center_2 {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #fbfdff;
}
.col-sm-4 .center_1_left_inner {
  border-bottom: 6px solid #8e1e22 !important;
}
.center_1_left_inner h6 {
  display: inline;
  text-align: center;
  font-size: 30px;
  color: #8e1e22 !important;
}
.center_1_main {
  padding-top: 30px;
}
.center_1_left_inner h4 {
  text-align: center;
  margin-top: -30px;
  font-family: "Exo 2", sans-serif !important;
  color: #8e1e22 !important;
}
.center_1_left_inner h4 a {
  text-decoration: none;
  font-family: "Exo 2", sans-serif !important;
  font-size: 1.8rem;
  line-height: 2.167rem;
  margin: 0;
  color: #8e1e22 !important;
}
.center_1_left_inner p {
  text-align: center;
  padding-top: 2.3rem;
  margin: 0;
  font-size: 1.5rem;
  line-height: 2.267rem;
  color: #606060;
  font-family: "Exo 2", sans-serif !important;
}
.center_1_left_inner .classic {
  list-style: none;
  padding: 1.2rem;
  padding-bottom: 0;
  padding-top: 2.3rem;
  font-size: 1.5rem;
  font-family: "muli-regular";
  color: #606060;
  margin-top: 0;
  margin-bottom: 1rem;
  list-style-type: circle;
  padding-bottom: 0;
  margin-bottom: -22px;
}
.center_1_left_inner .classic li {
  line-height: 2.267rem;
}

.center_1_left_inner {
  background: #fff;
  padding: 30px 15px;
  border: 1px solid #e1f0ff;
}

#popular {
  padding-top: 40px;
  padding-bottom: 40px;
}
.popular_2 {
  margin-top: 30px;
}
.popular_3 {
  background-color: #feffe8;
  padding: 15px;
  border: 1px dotted #fdffc8;
}

/*********************about_end****************/

@media screen and (max-width: 767px) {
  .center_1_left {
    margin-top: 10px;
  }
  .popular_3 {
    margin-top: 10px !important;
  }
  .center_1_left_inner p {
    text-align: left;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .popular_4 h4 {
    font-size: 20px;
  }
  .popular_4 p {
    font-size: 16px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
}
@media (min-width: 1201px) and (max-width: 1330px) {
}

/*********************services****************/
#off {
  padding-top: 40px;
  padding-bottom: 40px;
}
#connect {
  padding-bottom: 40px;
}
.connect_1 {
  background: #394336;
  padding: 40px 20px;
  margin-left: 0;
  margin-right: 0;
}
.connect_2 {
  margin-top: 30px;
}

.connect_2i {
  background: #f5f0e9;
  padding: 40px 20px;
  border: 1px solid #ddd;
}
.connect_2il span {
  font-size: 30px;
  line-height: 0;
}
.connect_2i:hover {
  background: #6d8f64;
}
.connect_2i:hover h5 {
  color: #fff;
}
.connect_2i:hover p {
  color: #fff;
}
.connect_2i:hover span {
  color: #fff !important;
}
/*********************services_end****************/

@media screen and (max-width: 767px) {
  .off1l h2 {
    font-size: 26px;
  }
  .off1r {
    margin-top: 10px;
  }
  .connect_1r {
    margin-top: 10px;
  }
  .connect_2ir {
    margin-top: 10px;
  }
  .connect_2i {
    margin-top: 10px !important;
    text-align: center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .off1l h2 {
    font-size: 26px;
  }
  .off1r img {
    height: 520px;
  }
  .connect_1r .button_1 {
    padding: 10px 10px 10px 10px;
  }
  .connect_2il span {
    font-size: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .off1r img {
    height: 520px;
  }
  .connect_1r .button_1 {
    padding: 10px 10px 10px 10px;
  }
  .connect_2il span {
    font-size: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1000px) {
  .story-img {
    width: 300px !important;
  }
  .story-img img {
    max-width: 100% !important;
  }
}
