.home-ar {
  direction: rtl;
}
.home-ar #about_h .mgt {
  letter-spacing: normal;
  word-spacing: normal;
  font-family: "cairo" !important;
  font-weight: bold;
}
.home-ar .about_h_1r li a {
  font-size: 16px;
  font-weight: 600;
}
.home-ar #home h4,
.home-ar #menu1 h4,
.home-ar #menu2 h4 {
  color: #000;
  letter-spacing: normal;
  word-spacing: normal;
}
.home-ar #home p,
.home-ar #menu1 p,
.home-ar #menu2 p {
  font-family: "cairo" !important;
}
.home-ar #menu2 p strong {
  font-family: bold;
  font-size: 15px;
  font-family: "cairo" !important;
}
@media (max-width: 991px) {
  .home-ar .nav_1 {
    margin-left: 50px;
  }
  .home-ar #home h4,
  .home-ar #menu1 h4,
  .home-ar #menu2 h4 {
    text-align: center;
  }
  .home-ar #home p,
  .home-ar #menu1 p,
  .home-ar #menu2 p {
    text-align: center;
  }
  .home-ar #menu2 p strong {
    text-align: right;
  }
}
.home-ar #farm .farm_1 h2 {
  font-family: "cairo" !important;
}

.home-ar .farm_2 .farm_2i h5 {
  font-family: "cairo" !important;
  color: #8e1e22 !important;
}
.home-ar .farm_2 .farm_2i p {
  font-family: "cairo" !important;
}
@media (max-width: 768px) {
  .home-ar .farm_2 .farm_2i h5,
  .home-ar .farm_2 .farm_2i p {
    text-align: center;
  }
}
.home-ar #sale .sale_1 h4 {
  font-family: "cairo" !important;
}
.home-ar #sale .sale_1 p {
  font-family: "cairo" !important;
}
.home-ar #sale .sale_1 h5 a {
  font-family: "cairo" !important;
}
@media (max-width: 768px) {
  .home-ar #sale .sale_1 h4,
  .home-ar #sale .sale_1 p {
    text-align: center;
  }
}
.home-ar #benef {
  direction: rtl;
}

.home-ar #benef .benef_1l h3 {
  font-weight: 900;
  letter-spacing: normal;
  word-spacing: normal;
  font-family: "cairo" !important;
}
@media (max-width: 768px) {
  .home-ar #benef {
    margin-top: -80px;
  }
}
.home-ar #benef .award_1 .award_1i h5 {
  font-family: "cairo" !important;
}
