.body_section {
  min-height: 400px;
  padding: 20px 0;
  overflow: hidden;
  position: relative;
}

.gallary_home .sec_title {
  margin-bottom: 70px;
  margin-top: 70px;
}
.center_view h3 {
  text-align: center;
}
h4 {
  color: #fff;
}
.center_view .float_text {
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
}
.sec_title .float_text {
  position: absolute;
  left: 0;
  top: -14px;
  font-size: 85px;
  color: #f5f5f5;
  font-weight: 700;
  text-transform: uppercase;
  right: 0;
  display: inline-block;
}
.animate-box {
  position: relative;
  opacity: 0;
  transition: all 1.1s ease-in-out;
  -o-transition: all 1.1s ease-in-out;
  -moz-transition: all 1.1s ease-in-out;
  -webkit-transition: all 1.1s ease-in-out;
}

.animate-box.animated {
  opacity: 1;
}

.animated {
  -webkit-animation-duration: 1.1s;
  animation-duration: 1.1s;
  animation-delay: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp,
.fadeInLeft,
.fadeInRight {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
/***/

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
/***/

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.pop_img img {
  height: 300px;
  width: 100%;
  border-radius: 15px;
}

.pop_img {
  overflow: hidden;
  position: relative;
  height: 300px;
  margin: 10px 0;
  cursor: pointer;
  background-color: #ddd;
}
.pop_img:hover .pop_over {
  opacity: 1;
  transform: translateX(0%);
  visibility: initial;
}
.pop_over {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(142 30 34 / 30%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transition: 0.5s;
  transform: translateX(-200%);
  visibility: hidden;
  text-align: center;
  padding: 10px;
}
.pop_over a {
  font-size: 30px;
  margin-top: 12px;
}

@media (max-width: 767px) {
  .gallary-image {
    margin-left: 15px;
  }
}
